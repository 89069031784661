// extracted by mini-css-extract-plugin
export var blocksChangingBackgroundColorOnHoverTitlesContainer = "payload-module--blocks-changing-background-color-on-hover-titles-container--bbf52";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--e831e";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--6d939";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--1a960";
export var cardsContainer = "payload-module--cards-container--4a7c2";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--eb7d0";
export var contactButtonImageSection = "payload-module--contact-button-image-section--e1744";
export var contactButtonImageSectionContainer = "payload-module--contact-button-image-section-container--ed79b";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--6021f";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--91bfd";
export var content = "payload-module--content--eefdb";
export var customFlipCardStyles = "payload-module--custom-flip-card-styles--bf361";
export var customSubtitle = "payload-module--custom-subtitle--b75ba";
export var flipCardsSectionInnerWrapper = "payload-module--flip-cards-section-inner-wrapper--fb94a";
export var flipCardsSectionTitlesContainer = "payload-module--flip-cards-section-titles-container--1f26c";
export var innerWrapperTechStack = "payload-module--inner-wrapper-tech-stack--51612";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--85f76";
export var paragraphWithGap = "payload-module--paragraph-with-gap--cf74a";
export var singleBlockContainer = "payload-module--single-block-container--a5c25";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--5d026";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--7544b";
export var titlesContainer = "payload-module--titles-container--aeb3f";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--b0a2c";