import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';
import Selector from '@images/logos-partners/Selector-Software-logo.svg';

import {
  react,
  angular,
  gatsby,
  vueJS,
  javaScript,
  typeScript,
  xState,
  mobX,
  redux,
  rxJS,
  d3JS,
  highcharts,
  agGrid,
  graphQL,
  reactQuery,
  cypress,
  reactTestingLibrary,
  storybook,
  playwright,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Frontend Development Services | CodiLime Company',
  description:
    'Seeking a reliable partner who can support you in frontend development and understands the network industry? Look no further, the CodiLime team awaits you!',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Frontend <br />
      development <br />
      services
    </>
  ),
  isBorder: false,
  classNames: {
    header: styles.headerStyles,
    innerWrapper: styles.headerInnerWrapper,
    titleStyle: styles.headerTitle,
  },
};

export const sectionUnderHeaderProps = {
  textPartOne:
    'The first experience that a user has when interacting with your software product can affect your business success. Give your product the necessary professional frontend services to meet customers’ needs and boost your business.',
  contactButtonLabel: 'Boost your frontend',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
  },
};

export const blocksInRowsWithOneActiveProps = {
  title: 'Frontend services tailored to your needs ',
  subtitle: (
    <>
      A good <b>frontend is more than just the visual side of your product,</b> it is the front gate of your business.
      The way customers interact with your product will determine how they view your services. It is especially crucial
      for network solutions that contain a lot of data - the user flow has to be flawless. Check out how our frontend
      engineers ensure that.
    </>
  ),
  blocks: [
    {
      title: 'Dashboard and widget design and development',
      description: (
        <>
          Our frontend engineers, in cooperation with the UX team, will develop <b>dashboards and widgets</b> for your
          application, based on your business and technical requirements.
        </>
      ),
    },
    {
      title: 'Infrastructure visualization',
      description: (
        <>
          We will help you to visualize infrastructure to improve its management. This includes preparing the{' '}
          <b>inventory presentation</b>, tree topology, graphs, and hybrid maps of the infrastructure.
        </>
      ),
    },
    {
      title: 'Data monitoring visualization',
      description: (
        <>
          We will <b>visualize your monitoring data</b>, which will help with a deeper analysis of monitored products,
          inventories, and infrastructures. We will provide metrics like time series data, aggregates, and TOPN values.
          Additionally, we will present environmental health, traceroutes, events and alerts.
        </>
      ),
    },
    {
      title: 'Easy installation',
      description: (
        <>
          We will design, <b>develop and implement wizards</b> to provide a seamless and stress-free installation path
          for complex software for your clients.
        </>
      ),
    },
    {
      title: 'Utilizing and extending existing platforms',
      description: (
        <>
          We integrate leading market or client-owned platforms through APIs or plugin frameworks, and we aggregate the
          metrics from such systems. We also <b>utilize visualizations</b> from the abovementioned solutions; for
          example, we embedded Grafana charts into a custom UI for a client.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveSection,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.customSubtitle,
    singleBlockContainer: styles.singleBlockContainer,
    titlesContainer: styles.titlesContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
  },
  title: <>Frontend that understands network industry needs</>,
  imageAlt: 'Frontend that is aware of the demands of the network sector',
  isH2: true,
  classNames: {
    customContent: styles.content,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionContainer,
  },
};

export const flipCardsSectionProps = {
  sectionProps: {
    title: 'Our frontend expertise at your service',
    subtitle: (
      <>
        At CodiLime, we support companies from various industries. We have been providing frontend development services
        for businesses such as: <b>monitoring platforms</b> (APM, NPM), security monitoring, <b>cloud monitoring</b>,
        digital infrastructure monitoring, data center monitoring, <b>flow control solutions</b>, SaaS platforms, AI/ML
        solutions, and more. We cooperate with startups, large-scale companies, and multi-service providers.
        <br className={styles.paragraphWithGap} />
        Check how we can boost your software product:
      </>
    ),
    classNames: {
      innerWrapper: styles.flipCardsSectionInnerWrapper,
      titlesContainer: styles.flipCardsSectionTitlesContainer,
    },
  },
  customCardsContainerStyles: styles.cardsContainer,
  customFlipCardStyles: styles.customFlipCardStyles,
  isFlippable: true,
  flipCardsData: [
    {
      title: 'Comprehensive management platforms',
      description: (
        <>
          Our frontend engineers will use all their accumulated experience to <b>create complex frontend platforms</b>{' '}
          to make application user flows, configuration management paths, monitoring processes and real time
          visualization efficient and simple to operate.
        </>
      ),
      color: '#14212A',
      paddingTop: '100px',
    },
    {
      title: 'Implementation of new functionalities',
      description: (
        <>
          Thanks to a wisely-chosen tech stack, our frontend team can ensure seamless and{' '}
          <b>painless implementation of new functionalities</b> for dashboards, reporting, and more.
        </>
      ),
      color: '#223745',
      paddingTop: '100px',
    },
    {
      title: 'Design with scalability in mind',
      description: (
        <>
          We know that sometimes it&apos;s hard to predict how much a product will grow or change, or what improvements
          will be needed. To ensure <b>seamless scaling of your product</b> we build components that can be easily
          reusable, so that you can develop and change your product with us practically on demand.
        </>
      ),
      color: '#1C2C38',
      paddingTop: '100px',
    },
  ],
};

export const blocksChangingBackgroundColorOnHoverProps = {
  title: 'Frontend services - our approach',
  subtitle: (
    <>
      We know how our partners value flexibility, support at every process stage, and transparency. At CodiLime, we
      appreciate the same qualities so <b>we create strong ties with our clients</b>. As a result, our partnerships are
      goal-oriented and aim to provide the best possible results.
    </>
  ),
  classNames: { titlesContainer: styles.blocksChangingBackgroundColorOnHoverTitlesContainer },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Our frontend technology stack',
    subtitle: (
      <>
        What does our frontend toolkit look like in detail? Here is a full list of the{' '}
        <b>technologies you can expect from us</b>!
      </>
    ),
  },
  classNames: {
    innerWrapper: styles.innerWrapperTechStack,
  },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Frameworks',
      technologiesData: [react, angular, vueJS, gatsby],
    },
    {
      categoryTitle: 'Programming languages',
      technologiesData: [javaScript, typeScript, xState],
    },
    {
      categoryTitle: 'Libraries',
      technologiesData: [mobX, redux, rxJS, d3JS, highcharts, agGrid, graphQL, reactQuery],
    },
    {
      categoryTitle: 'Testing',
      technologiesData: [cypress, reactTestingLibrary, storybook, playwright],
    },
  ],
};

export const sliderWithClickableBlocksProps = {
  sectionProps: {
    title: 'Our service models',
    subtitle: (
      <>
        At CodiLime, we value maximum freedom and flexibility – that&apos;s why we offer them to our partners. To meet a
        variety of needs and requirements, we have <b>several cooperation models</b>. You can choose from team
        augmentation, a tailored team, end-to-end services, or consulting & expertise. Everything aims to make our
        partnership splendid.
      </>
    ),
  },
  imgAlt: 'frontend and other development service models',
  classNames: {
    section: styles.sliderWithClickableBlocksSection,
    innerWrapper: styles.sliderWithClickableBlocksInnerWrapper,
  },
};

export const testimonialProps = {
  id: 'testimonial',
  testimonial: {
    quote:
      'CodiLime with its expertise in both network engineering and software development helped us build and ' +
      'release a platform for operational intelligence of digital infrastructure. CodiLime UX designers and software ' +
      'engineers did a great job to ensure great user experience of our product. Smooth communication, out-of-the-box ' +
      'thinking and reliability are those factors that also make our cooperation great.',
    author: 'Rudra Rugge - VP of Engineering',
    logo: Selector,
    logoAlt: 'Selector',
  },
  leftTitle: 'A client’s opinion on our services',
  classNames: {
    innerWrapper: styles.innerWrapperTestimonials,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Frontend-related publications on our blog',
    subtitle: (
      <>
        Want to dive deeper into the frontend development topic? Check out our blog, where our developers share their
        knowledge and experience.
      </>
    ),
    classNames: { titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer },
  },
  blocks: [
    {
      text: 'API-First design – what is it and how to use it?',
      link: '/blog/api-first-design/',
    },
    {
      text: 'Axios vs. Fetch API – which is better for HTTP requests?',
      link: '/blog/axios-vs-fetch/',
    },
    {
      text: 'CSS Container Queries. Why so enthusiastic about those?',
      link: '/blog/css-container-queries/',
    },
    {
      text: 'MobX in React – Scalable State Management',
      link: '/blog/mobx-in-react-scalable-state-management/',
    },
    {
      text: 'MobX in React – learn the basics [Video Demonstration]',
      link: '/blog/mobx-in-react-learn-the-basics-video-demonstration/',
    },
    {
      text: 'Why should you be grateful for act() warnings in React tests',
      link: '/blog/why-should-you-be-grateful-for-act-warnings-in-react-tests/',
    },
    {
      text: 'Where are my keys? React keys',
      link: '/blog/where-are-my-keys-react-keys/',
    },
    {
      text: 'Next.js – what do you need to know',
      link: '/blog/nextjs-what-do-you-need-to-know/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'We have more to offer',
  subtitle: (
    <>
      Besides seamless frontend development for your network solution, you can think about improving other aspects of
      your product. <b>Our specialists are network-oriented</b> and have broad experience in working with different
      network & monitoring solutions.
    </>
  ),
  isFlippable: true,
  blocks: [
    {
      text: (
        <>
          <span>UX/UI</span>
        </>
      ),
      description: (
        <>
          Poor visuals and overall user experience can discourage most modern end-users from using products – to avoid
          that, focus on <b>UX that understands network engineering.</b>
        </>
      ),
      link: paths.SERVICES.UX_UI_DESIGN,
    },
    {
      text: (
        <>
          <span>Software development </span>
          <span>for startups</span>
        </>
      ),
      description: (
        <>
          We know that beginnings can be difficult – especially when you’re a startup. That’s why we want to
          <b> support you at every stage </b> of growing your business and project.
        </>
      ),
      link: paths.SERVICES.SOFTWARE_DEVELOPMENT,
    },
    {
      text: (
        <>
          <span>Custom software </span>
          <span>development services</span>
        </>
      ),
      description: (
        <>
          Building a product from scratch with <b>custom-selected technologies</b> allows for creating a unique project
          containing only the necessary features.
        </>
      ),
      link: paths.SERVICES.CUSTOM_DEVELOPMENT,
    },
    {
      text: (
        <>
          <span>MVP development</span>
        </>
      ),
      description: (
        <>
          Looking for a partner who understands the network perspective and challenges? We’ve been creating
          <b> MVPs for network solutions since 2011. </b>
        </>
      ),
      link: paths.SERVICES.MVP,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksInnerWrapper,
  },
};

export const scrolledSliderProps = {
  title: 'How our frontend development services can benefit your business',
  subtitle: (
    <>
      The frontend takes care of both customer service and easy interaction with your product and its visual side. A
      frontend together with a properly-designed user interface (UI) creates a strong base that allows for{' '}
      <b>successful product positioning in the market.</b>
      <br /> How can investing in good frontend services positively impact your company?
    </>
  ),
  textBlocks: [
    {
      title: 'Adjustment to the product',
      description: (
        <>
          Depending on the project requirements, our frontend developers can <b>build a solution from scratch</b> or add
          needed touch-ups to an existing one. In the first approach, we can offer you a tailor-made product. However,
          modifying ready-made parts of solutions already available on the market will also give your product an
          additional boost.
        </>
      ),
    },
    {
      title: 'Seamless scalability',
      description: (
        <>
          The tools that we have selected provide ready-to-use components. They are perfect solutions for products for
          which further growth is hard to predict, as <b>scaling is easier and quicker</b> than writing new code. This
          fact could be especially critical in the startup environment when resources are often limited.
        </>
      ),
    },
    {
      title: 'Integration and responsiveness',
      description: (
        <>
          Using only market-standard technologies, we&apos;re confident that your product will be{' '}
          <b>easily integrated with different operating systems</b> to make it accessible to the majority of users.
          Moreover, responsive design provides a great user experience no matter the user&apos;s device, and without
          compromising performance.
        </>
      ),
    },
    {
      title: 'Reusable code',
      description: (
        <>
          Prebuilt components and the ability to reuse code parts accelerate the developers&apos; work and reduce the
          amount of code that needs to be written from scratch. That shortens the time-to-market of new features and
          offers a <b>faster response to users&apos; needs</b>, so your product becomes more appealing to a modern
          audience.
        </>
      ),
    },
  ],
};
