import React from 'react';

import Layout from '@src/layouts';

import {
  MobileHeaderLayoutA,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  BlocksInRowsWithOneActive,
  ContactButtonImageSection,
  FlipCardsSection,
  BlocksChangingBackgroundColorOnHover,
  TechStackWithCategories,
  SliderWithClickableBlocks,
  BlocksRedirectingToResources,
  NewClientSingleTestimonial,
  ColoredBlocksWithRedirects,
  ScrolledSlider,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveProps,
  contactButtonImageSectionProps,
  flipCardsSectionProps,
  blocksChangingBackgroundColorOnHoverProps,
  techStackWithCategoriesProps,
  sliderWithClickableBlocksProps,
  testimonialProps,
  blocksRedirectingToResourcesProps,
  coloredBlocksWithRedirectsProps,
  scrolledSliderProps,
} from '@pages-impl/frontend-development/payload';

export default function FrontendDevelopment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Develop the frontend of your information-heavy software"
      {...props}
    >
      <SEO {...SEOProps} />
      <MobileHeaderLayoutA {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <FlipCardsSection {...flipCardsSectionProps} />
      <ScrolledSlider {...scrolledSliderProps} />
      <BlocksChangingBackgroundColorOnHover {...blocksChangingBackgroundColorOnHoverProps} />
      <TechStackWithCategories {...techStackWithCategoriesProps} />
      <SliderWithClickableBlocks {...sliderWithClickableBlocksProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
